


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { VideoRecorderField, IFormField } from 'client-website-ts-library/types/Forms/FieldTypes';
import { FormSubmissionValidationError, FormValue } from 'client-website-ts-library/types/Forms';

import { VideoRecorder } from 'client-website-ts-library/components';

@Component({
  components: {
    VideoRecorder,
  },
})
export default class FormVideoUploadField extends Vue implements IFormField {
  @Prop()
  private readonly def!: VideoRecorderField;

  @Prop()
  private readonly errors!: FormSubmissionValidationError[];

  private videoBlob: Blob | null = null;

  private videoBlobUrl: string | null = null;

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  private handleDone(videoBlob: Blob | null) {
    this.videoBlob = videoBlob;

    if (this.videoBlobUrl !== null) {
      URL.revokeObjectURL(this.videoBlobUrl!);
      this.videoBlobUrl = null;
    }

    if (this.videoBlob !== null) {
      this.videoBlobUrl = URL.createObjectURL(this.videoBlob);
    }

    (this.$refs.preview as HTMLVideoElement).src = this.videoBlobUrl ?? '';
  }

  private recordNewVideo() {
    (this.$refs.recorder as unknown as VideoRecorder).requestRecording();
  }

  private deleteVideo(): void {
    this.handleDone(null);
  }

  getValue(): FormValue {
    return {
      Key: this.def.Key,
      Value: this.videoBlob,
    };
  }

  setValue(value: FormValue) {
    if (value.Value instanceof Blob || value.Value === null) {
      this.videoBlob = value.Value;
    }
  }
}
